import { modulesName } from '@/modules';
import store from '@/store';

export const userMixin = {
  computed: {
    userAuthenticated() {
      return store.getters[`${modulesName.userModuleName}/authenticated`];
    },
    userRole() {
      // return store.getters[`${modulesName.userModuleName}/role`]
      //Valid 2 case waiting for change authenticated for save correct user
      let role = store.getters[`${modulesName.userModuleName}/role`];
      return role;
    },
    userProfile() {
      let profile = store.getters['$_user/fullProfile'];
      return profile;
    },
  },
  methods: {
    userHasPermissions(...permissions) {
      return store.getters[`${modulesName.userModuleName}/hasPermissions`](
        permissions
      );
    },
    userHasRole(...role) {
      //Use Store for get currently roles
      return role;
      // return store.getters[`${modulesName.userModuleName}/hasPermissions`](
      //     permissions
      // )
    },
  },
};
