const environment = {
  Training: "Training"
}

//Common Mixins
export const commonMixin = {
  methods: {      
    isTrainingEnv(){      
      return process.env.VUE_APP_ENVIRONMENT == environment.Training;      
    }
  },
};