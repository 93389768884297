import moment from 'moment';
import Vue from 'vue';

Vue.filter('formatDate', (value, format = null) => {
  if (value) {
    return moment(value)
      .locale('es-PR')
      .format(format == null ? 'DD/MMM/YYYY' : format);
  }
});

Vue.filter('maskSSN', (value) => {
  if (value) {
    return value.replace(new RegExp('.{5}(?=.{4}$)'), '***-**-');
  }
});

Vue.filter('studentSie', (value) => {
  value = value || '';
  return `${value.replace(/[0-9]/gi, '')} - ${value.replace(
    /[a-z A-Z À-ú]/gi,
    ''
  )}`;
});

Vue.filter('empty', (value) => {
  return !value ? '-' : value;
});

Vue.filter('formatBoolean', (value) => {
  return value ? 'Si' : 'No';
});

Vue.filter('price', (value) => {
  let val = (value / 1).toFixed(2);
  return '$' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
});

Vue.filter('uppercase', (value) => {
  if (!value) return value;
  return value.toUpperCase();
});

export default () => {
  Vue.prototype.$groupBy = (xs, f) => {
    return xs.reduce(
      (r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r),
      {}
    );
  };

  Vue.prototype.$deleteArrayDuplicates = (array, filterId) => {
    let result = [];

    for (let i = 0; i < array.length; i++)
      if (!result.some((x) => x[filterId] == array[i][filterId])) {
        result.push(array[i]);
      }

    return result;
  };
};

const CUSTOM_LOCALE = 'es-PR';

export const phone = (value) => {
  if (value == undefined || value == '') return '-';

  return value
    .replace(/[^0-9]/g, '')
    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
};

export const studentSie = (value) => {
  value = value || '';
  return `${value.replace(/[0-9]/gi, '')} - ${value.replace(
    /[a-z A-Z À-ú]/gi,
    ''
  )}`;
};

export const price = (value) => {
  let val = (value / 1).toFixed(2);
  return '$' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const currency = (value) => {
  if (value == undefined || value == '') return '-';

  return value.toLocaleString('en-US', { minimumFractionDigits: 2 });
};

export const ssn = (value) => {
  if (value === undefined || value === '') return '';

  const lastDigits = value.substr(-4);
  return `xxx-xx-${lastDigits}`;
};

export const capitalize = (value) => {
  if (!value) return '';
  value = value.toString();

  return value
    .toLowerCase()
    .split(' ')
    .map((w) => w.substring(0, 1).toUpperCase() + w.substring(1))
    .join(' ');
};

export const daywithnumber = (value) => {
  if (!value) return '';
  value = value.toString();

  return moment(value)
    .locale(CUSTOM_LOCALE)
    .format('DD - dddd')
    .replace(/ 0(?:0)?/, ' ')
    .split(' ')
    .map((w) => w.substring(0, 1).toUpperCase() + w.substring(1))
    .join(' ');
};

export const date = (value) => {
  if (value == undefined || value == '') return '-';

  let result = moment(value)
    .locale(CUSTOM_LOCALE)
    .format('DD/MMM/YYYY')
    .replace(/ 0(?:0)?/, ' ');

  return result;
};

export const formatDateWithHour = (value) => {
  if (value) {
    let d = new Date(value);
    let datestring =
      ('0' + (d.getMonth() + 1)).slice(-2) +
      '/' +
      ('0' + d.getDate()).slice(-2) +
      '/' +
      d.getFullYear();
    let hours = d.getHours();
    let minutes = d.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return datestring + ' ' + strTime;
  }
};

export const DoesntApply = (value) => value || 'No Aplica';
