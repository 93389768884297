import Vue from 'vue';
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  setInteractionMode,
} from 'vee-validate';

import { messages } from 'vee-validate/dist/locale/es.json';
import * as rules from 'vee-validate/dist/rules';

Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);

Object.keys(rules).forEach((rule) => {
  let ruleObj = {
    ...rules[rule], // copies rule configuration
    message: messages[rule], // assign message
  };
  extend(rule, ruleObj);
});

extend('url', {
  validate: (str) => {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(str);
  },
  message: 'No es una URL válida',
});

extend('money', {
  validate: (value) => {
    if (value == null || value == undefined) return false;

    let regex = /^\d*\.?\d*$/g;
    return value.toString().search(regex) !== -1;
  },
});

extend('ssn', {
  getMessage: () => 'El número de seguro social entrado no es valido.',
  validate: (value) => {
    if (value == null || value == undefined) return false;

    let regex = /^\d{3}-\d{2}-\d{4}$/g;

    return value.search(regex) !== -1;
  },
});

extend('ssnpatronal', {
  getMessage: () => 'El número de seguro social entrado no es valido.',
  validate: (value) => {
    if (value == null || value == undefined) return false;

    let regex = /^\d{3}-\d{6}$/g;

    return value.search(regex) !== -1;
  },
});

extend('hasSymbolsNumbersLowerCPassword', (value) => {
  let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z])/g;
  if (value.search(regex) !== -1) return true;
  return 'La contraseña debe incluir mínimo una letra mayúscula, un símbolo y número';
});

extend('hasCorrectSizeCPassword', (value) => {
  let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{6,16}$/g;
  if (value.search(regex) !== -1) return true;
  return 'Este campo no debe ser menor de 6 caracteres ni mayor a 16 caracteres.';
});

extend('isEqualToCPassword', (value, args) => {
  if (args.length > 0) {
    if (value === args[0]) return true;
  }
  return 'Esta contraseña debe ser igual a la entrada en el campo de nueva contraseña.';
});

extend('mustBeDifferentThanCPassword', (value, args) => {
  if (args.length > 0) {
    if (value !== args[0]) return true;
  }
  return 'La contraseña nueva no puede ser igual a la anterior.';
});

extend('differentFromZero', (value) => {
  if (!isNaN(value) && value != 0) return true;
  return 'Favor de añadir un valor diferente de cero';
});
extend('smallerThanTwo', {
  validate: (value) => !isNaN(value) && value > 2,
  getMessage: () => 'Favor de añadir un valor mayor de dos.',
});
extend('greaterThanZero', {
  validate: (value) => !isNaN(value) && value > 0,
  getMessage: () => 'Favor de añadir un valor mayor de cero.',
});

extend('notEmptyObject', (value, args) => {
  if (args.length > 0) {
    const key = args[0];
    if (value[key] > 0 && value[key] !== '') return true;
  }
  return 'El campo es requerido';
});
setInteractionMode('eager');

extend('phone', {
  validate: (value) => value && value.length === 10,
});

extend('excludeDomainDePrGov', {
  message: 'El correo electrónico no debe ser de la agencia de.pr.gov',
  validate: (value) => {
    return !!value && !value.includes('@de.pr.gov');
  },
});

extend('workdayTime', {
  message: 'La jornada diaria debe ser en formato (HH:mm) entre 01:00 y 08:00',
  validate: (value) => {
    if (value == null || value == undefined) return false;

    let regex = /^(0[1-7]{1}:[0-5]{1}\d{1})|08:00$/g;

    return value.search(regex) !== -1;
  },
});

extend('contractTemplateTypeRequired', {
  getMessage: () => 'Debe agregar al menos un tipo de contrato.',
  validate: (value) => {
    if (value == null || value == undefined) return false;

    if (value.length > 0) return true;

    return 'Debe agregar al menos un tipo de contrato.';
  },
  computesRequired: true,
});

extend('contractTemplateRequired', {
  getMessage: () => 'El mensaje es requerido.',
  validate: (value) => {
    if (value == null || value == undefined) return false;

    const regex = /(<([^>]+)>)/gi;
    const strWithoutTag = value.replace(regex, '');

    if (strWithoutTag && strWithoutTag != '') return true;

    return 'El mensaje es requerido.';
  },
  computesRequired: true,
});
