import Vue from 'vue';
import Moment, { locale } from 'moment';
import VueMoment from 'vue-moment';
import { extendMoment } from 'moment-range';

locale('es-PR');

const moment = extendMoment(Moment);

Vue.use(VueMoment, { moment });
