export const studentStatus = Object.freeze({
  PreRegistro: 125,
  Registro: 126,
  Evaluado: 127,
  Servido: 128,
  NoElegible: 129,
  Egreso: 131,
  Disponible: 200,
  Inactivo: 219,
  Elegible: 303,
});
