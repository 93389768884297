import Vue from 'vue';
import moment from 'moment';

// Axios uses JSON.Stringify which by default converts the DateTime objects to UTC
// To prevent this we override the toJSON function so it gets the string properly
Date.prototype.toJSON = function() {
  return moment(this).format();
};

export default () => {
  const templates = {
    default: 'DD/MMM/YYYY',
    numberMonthDayYear: 'MM/DD/YYYY',
    hour: 'hh:mm A',
    hourWithWeekDay: 'ddd hh:mm A',
    longDateFormat: 'dddd, DD MMMM YYYY',
  };

  Vue.prototype.$templateDate = templates;

  Vue.prototype.$formatDate = (date, template = templates.default) => {
    if (date == null) {
      return '';
    }

    return moment(date)
      .locale('es-PR')
      .format(template);
  };
};
