import { BASE_URL } from '@/config';
import Vue from 'vue';
import VueRouter from 'vue-router';
import { applyEvents } from './events';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: BASE_URL,
  routes,
});

applyEvents(router);

export default router;
