import Vue from 'vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.use(Loading);

const Loader = {
  data() {
    return {
      loading: null,
    };
  },
  methods: {
    ShowWait(parentcomponent) {
      let self = this;
      self.loading = self.$loading.show({
        container: parentcomponent,
        canCancel: false,
        onCancel: self.onCancel,
        loader: 'bars',
        color: '#41b883',
        backgroundColor: '#ffffff',
        width: 90,
        height: 90,
        zIndex: 9999,
      });
    },
    HideWait() {
      let self = this;
      if (self.loading !== null) {
        self.loading.hide();
      }
    },
  },
};

export default Loader;
