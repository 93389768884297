import { studentEvaluationStatus } from './student-evaluation-status';
import { uiColors } from './ui-color';

export const studentEvaluationStatusColor = Object.freeze({
  [studentEvaluationStatus.Admitido]: uiColors.Yellow,
  [studentEvaluationStatus.Asignado]: uiColors.Yellow,
  [studentEvaluationStatus.Vinculado]: uiColors.Yellow,
  [studentEvaluationStatus.ReferidoDirectorCSEE]: uiColors.Brown,
  [studentEvaluationStatus.ReferidoRechazado]: uiColors.Brown,
  [studentEvaluationStatus.ServicioRelacionadoActivo]: uiColors.Green,
  [studentEvaluationStatus.NoVinculado]: uiColors.Pink,
  [studentEvaluationStatus.Alertas]: uiColors.Pink,
  [studentEvaluationStatus.AprobadoRemedioProvisional]: uiColors.Purple,
});
