import '@/assets/scss/v2/main.scss';

import datePlugin from '@/plugins/date.plugin';

// Auth
import { startup as authStartup } from '@/core/auth';
import { userMixin } from '@/modules/user/mixins/user.mixins';
import { commonMixin } from '@/mixins/common.mixin';


//Extend
import filterUtils from '@/utils/filters';

//libs

//Install Libraries
import 'bootstrap/dist/js/bootstrap.min.js';

//App
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import '@/plugins';
import '@/filters';


import vSelect from 'vue-select';
Vue.component('v-select', vSelect);
import 'vue-select/dist/vue-select.css';

import { ValidationObserver, ValidationProvider } from 'vee-validate';
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

import httpPlugin from '@/plugins/http.plugin';
import VueGoodTablePlugin from 'vue-good-table';
import goodTableCustomPager from '@/components/common/goodTableCustomPager.vue';

Vue.use(httpPlugin);
Vue.use(VueGoodTablePlugin);
Vue.component('customPager', goodTableCustomPager);

//Set Extends Vue functionalitis
Vue.use(filterUtils);
Vue.mixin(userMixin);
Vue.mixin(commonMixin);
Vue.use(datePlugin);

Vue.config.productionTip = false;

const $ = require('jquery');
window.$ = $;

authStartup().then((ok) => {
  if (ok) {
    new Vue({
      store,
      router,
      render: (h) => h(App),
      mounted() {
        if (this.isTrainingEnv()) {
          const favicon = $('#favicon');             
          favicon.attr('href', '/favicon-training.ico');          
        } 
      }
    }).$mount('#app');
  }
});


