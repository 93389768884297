export const uiColors = Object.freeze({
  Brown: 'brown',
  Cream: 'cream',
  Gray: 'gray',
  Green: 'green',
  LightGreen: 'green-light',
  Orange: 'orange',
  Peach: 'peach',
  Pink: 'pink',
  Purple: 'purple',
  Yellow: 'yellow',
});
