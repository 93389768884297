export const studentEvaluationStatus = Object.freeze({
  NoVinculado: 1,
  ServicioRelacionadoActivo: 4,
  NoVigenteAlta: 5,
  NoVigenteBaja: 6,
  Alertas: 9,
  Admitido: 11,
  Vinculado: 12,
  Asignado: 13,
  NoVigenteFinYearEscolar: 14,
  NoVigenteTraslado: 18,
  ReferidoRechazado: 22,
  ReferidoDirectorCSEE: 23,
  AprobadoRemedioProvisional: 24,
});
