import Swal from 'sweetalert2';
import toastType from '@/utils/constants/toastTypes.js';

const SwAlert = {
  data: () => ({
    toastType,
  }),
  methods: {
    ShowToast: (Title, Message, MsjType, Callback) => {
      const defautlTitle = {
        success: 'Proceso exitoso',
        warning: 'Atención',
        error: 'Error',
      };
      let title = Title ? Title : defautlTitle[MsjType];
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 6000,
        onOpen: Callback,
      });
      Toast.fire(title, Message, MsjType);
    },
    ShowAlert: (Title, Message, MsjType, Callback) => {
      Swal.fire({
        title: Title,
        text: Message,
        icon: MsjType,
        allowOutsideClick: false,
        onClose: Callback,
      });
    },
    ShowModalComponents(ComponentName) {
      Swal.fire({
        html: ComponentName,
      });
    },
    ShowConfirm: async (title, Question) => {
      let answer = false;
      await Swal.fire({
        title: title,
        text: Question,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'No',
        confirmButtonText: 'Si',
      }).then(function(response) {
        if (response.value) {
          answer = response.value;
        }
      });
      return answer;
    },
    GetValidationMessages: async (messageCode) => {
      return this.$ApiGet(
        '/api/validationsmessages/messageCode?=' + messageCode
      );
    },
    ShowCancelModal: async (
      title,
      message,
      confirmButtonText = 'Sí',
      cancelButtonText = 'No'
    ) => {
      let answer = false;
      await Swal.fire({
        title: '',
        html: `
                    <div class="custom-cancel-popup">
                    <div class="header shadow">
                        <div class="title">
                            <div class="icon-container">
                              <i class="fas fa-bell-exclamation circle-icon"></i>
                            </div>
                            <span>${title}</span>
                        </div>
                    </div>
                    <div class="body mt-5">
                        <div class="content py-3 px-4">
                            <span >${message}</span>
                        </div>
                    </div>
                </div>
                    `,
        icon: 'warning',
        focusConfirm: false,
        focusCancel: false,
        showCancelButton: true,
        showConfirmButton: true,
        showCloseButton: false,
        allowOutsideClick: false,
        confirmButtonColor: '#2DB782',
        cancelButtonColor: '#D01C1C',
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        customClass: 'custom-cancel-popup',
      }).then(function(response) {
        if (response.value) {
          answer = response.value;
        }
      });
      return answer;
    },
    ShowAlertModal: (Title, Message, MsjType, Callback) => {
      let icon = '';
      switch (MsjType) {
        case 'success':
          icon = 'fa-check circle-icon';
          break;
        case 'error':
          icon = 'fa-times circle-icon';
          break;
        default:
          icon = 'fa-exclamation circle-icon';
      }

      Swal.fire({
        html: `
                    <div class="custom-cancel-popup">
						<div class="header shadow">
							<div class="title">
								<div class="icon-container">
									<i class="fas ${icon}"></i>
								</div>
								<span>${Title}</span>
							</div>
						</div>
						<div class="body mt-5">
							<div class="content py-3 px-4">
								<span>${Message}</span>
							</div>
						</div>
              	  </div>
					`,
        allowOutsideClick: false,
        confirmButtonColor: '#2DB782',
        onClose: Callback,
        showConfirmButton: true,
        customClass: 'custom-cancel-popup',
      });
    },
  },
};

export default SwAlert;
