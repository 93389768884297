<template>
  <div v-if="total > perPage" class="row table-pager">
    <div class="col-6">
      <div class="pull-left align-middle">
        Mostrando {{ startRow }} al {{ lastRow }} de {{ total }} resultados
      </div>
    </div>
    <div class="col-6">
      <nav class="float-right">
        <ul class="pagination">
          <li class="page-item">
            <a
              aria-label="Previous"
              class="page-link"
              href="#"
              @click.prevent="goToPrevPage"
            >
              <i class="fa fa-angle-left" />
            </a>
          </li>

          <li
            v-for="p in availablePageList"
            :key="p"
            class="page-item"
            :class="{ active: currentPage == p }"
          >
            <a class="page-link" href="#" @click.prevent="customPageChange(p)">
              {{ p }}
            </a>
          </li>

          <li class="page-item">
            <a
              aria-label="Next"
              class="page-link"
              href="#"
              @click.prevent="goToNextPage"
            >
              <i class="fa fa-angle-right" />
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  meta: {
    description:
      'Componente que indica existe una serie de contenido relacionado en varias páginas.',
    slots: {
      default: {
        type: 'component',
        valid: [],
      },
    },
  },
  props: {
    pageChanged: {
      type: Function,
      default: function() {},
      meta: {
        description: 'Establece la página cambiada',
        example: '',
      },
    },
    perPageChanged: {
      type: Function,
      default: function() {},
      meta: {
        description: 'Página de referencia a cambiar',
        example: '',
      },
    },
    perPage: {
      type: Number,
      default: 0,
      meta: {
        description: 'Página de referencia mostrada',
        example: '5',
      },
    },
    externalCurrentPage: {
      type: Number,
      default: 1,
      meta: {
        description:
          'External Pagina Actual para tomar como referencia la actual pagina en caso que se reinicie a 1',
        example: '5',
      },
    },
    total: {
      type: Number,
      default: 0,
      meta: {
        description: 'Total de contenido relacionado existente',
        example: 'Número requerido',
      },
    },
  },
  data: function() {
    return {
      currentPage: this.externalCurrentPage > 1 ? this.externalCurrentPage : 1,
      startRow: 1,
      lastRow: 10,
      //ECG:TODO
      //maxPageSelectors:4
    };
  },
  computed: {
    availablePageList() {
      let totalPages = this.calcTotalPages();
      return totalPages > 4
        ? this.currentPage > 2 && this.currentPage < totalPages - 1
          ? [1, 2, this.currentPage, totalPages - 1, totalPages]
          : [1, 2, totalPages - 1, totalPages]
        : [...Array(totalPages + 1).keys()].slice(1);
    },
  },
  watch: {
    currentPage: function() {
      this.setStartRow();
      this.setLastRow();
    },
    total: function() {
      this.setStartRow();
      this.setLastRow();
    },
  },
  methods: {
    setStartRow() {
      this.startRow = this.perPage * (this.currentPage - 1) + 1;
    },
    setLastRow() {
      let temp = this.perPage * this.currentPage;
      this.lastRow =
        temp > this.total
          ? temp - this.perPage + (this.total % this.perPage)
          : temp;
    },
    reset() {
      this.currentPage = 1;
      this.pageChanged({ currentPage: 1 });
      this.setStartRow();
      this.setLastRow();
    },
    customPageChange(customCurrentPage) {
      this.currentPage = customCurrentPage;
      this.pageChanged({ currentPage: customCurrentPage });
    },
    customPerPageChange(customPerPage) {
      this.perPageChanged({ currentPerPage: customPerPage });
    },
    goToNextPage() {
      if (this.currentPage < this.calcTotalPages()) {
        this.currentPage++;
        this.pageChanged({ currentPage: this.currentPage });
      }
    },
    goToPrevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.pageChanged({ currentPage: this.currentPage });
      }
    },
    calcTotalPages() {
      return Math.trunc(
        this.total % this.perPage > 0
          ? this.total / this.perPage + 1
          : this.total / this.perPage
      );
    },
  },
  mounted() {
    this.setLastRow(); //Validate Last Row
  },
};
</script>

<style scope>
.page-item.active .page-link {
  border-color: transparent !important;
}
</style>
