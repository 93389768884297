<template>
  <MediaQueryProvider :queries="{ mobile: '(max-width: 680px)' }">
    <router-view />
  </MediaQueryProvider>
</template>

<script>
import { MediaQueryProvider } from 'vue-component-media-queries';
import loading from '@/mixins/loading.mixin';
import alert from '@/mixins/alert.mixin';

export default {
  components: { MediaQueryProvider },
  mixins: [loading, alert],
  computed: {
    isLoading() {
      return this.$store.getters['$_app/isAppLoading'];
    },
    isNotification() {
      const notice = !this.$store.getters['$_app/isNotificated'];
      return notice && this.isLoading == false; //Both true (show message)
    },
    notification() {
      return this.$store.state['$_app'].notification;
    },
  },
  watch: {
    isLoading: {
      immediate: true,
      handler(val) {
        if (val === true) {
          this.ShowWait();
        } else {
          this.HideWait();
        }
      },
    },
    isNotification(val) {
      if (val) {
        this.$store.state['$_app'].notification.showed = true;
        this.ShowToast(
          this.notification.title,
          this.notification.message,
          this.notification.type
        );
      }
    },
  },
};
</script>
