// import Vue from "vue";
import { authHandler } from '@/core/api/interceptor/auth-handler';
import store from '@/store';
import Swal from 'sweetalert2';

const whitelistEndpointsForHideToaster = [
  'api/student/getstudent',
  'api/documents',
];

// Intercepts

const requestErrorHandler = (error) => {
  store.dispatch('$_app/setLoading', false);
  //Show toast when request is error ocurrs
  return Promise.reject({ ...error });
};

const requestSuccessHandler = (request) => {
  store.dispatch('$_app/setLoading', true);
  authHandler(request);
  return request;
};

//Response Handlers
const responseErrorHandler = (error) => {
  store.dispatch('$_app/setLoading', false);
  if (error != undefined && error != null && error.response != undefined) {
    //Show the toast of Error in response
    const statusCode = error.response.status;
    let title = '';
    let toastType = 'error';
    let message = '';
    switch (statusCode) {
      case 401:
        message = 'Debe iniciar sesion nuevamente para continuar';
        break;
      case 404:
        title = 'Alerta';
        message =
          error.response.data.message !== null
            ? error.response.data.message
            : error.response.statusText;
        break;
      case 500:
        if (error.response.data.message != undefined) {
          message = error.response.data.message;
        } else {
          message =
            'Ocurrio error inexperado' +
            (error.response.data !== null
              ? error.response.data
              : error.response.statusText);
        }
        break;
    }

    store.dispatch('$_app/setLoading', false);

    //verify if the response endpoint is present in the whitelist to prevent sweat-alert
    if (
      !whitelistEndpointsForHideToaster.filter((x) =>
        error.response.config.url.includes(x)
      ).length > 0
    ) {
      Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 6000,
      }).fire(title, message, toastType);
    }
    return Promise.reject({ ...error });
  }
};

const responseSuccessHandler = (response) => {
  store.dispatch('$_app/setLoading', false);
  return response;
};

export default {
  requestErrorHandler,
  requestSuccessHandler,
  responseErrorHandler,
  responseSuccessHandler,
};
