import { studentStatus } from './student-status';
import { uiColors } from './ui-color';

export const studentStatusColor = Object.freeze({
  [studentStatus.Disponible]: uiColors.Brown,
  [studentStatus.Egreso]: uiColors.Cream,
  [studentStatus.Elegible]: uiColors.Yellow,
  [studentStatus.Evaluado]: uiColors.Yellow,
  [studentStatus.Inactivo]: uiColors.Brown,
  [studentStatus.NoElegible]: uiColors.Purple,
  [studentStatus.PreRegistro]: uiColors.Pink,
  [studentStatus.Registro]: uiColors.Yellow,
  [studentStatus.Servido]: uiColors.Green,
});
