import store, { modulesName } from '@/store';

export const applyEvents = (router) => {
  router.beforeEach(async (to, _, next) => {
    //Always init User module to get User Information
    if (await store.getters[`${modulesName.userModuleName}/authenticated`]) {
      store.dispatch(`${modulesName.userModuleName}/init`);
    } else {
      await store.dispatch(`${modulesName.userModuleName}/init`);
    }
    const meta = to.matched
      .filter((x) => x.meta)
      .map((x) => x.meta)
      .reduce((x, y) => ({ ...x, ...y }), {});
    if (meta.authorization) {
      if (meta.authorization.authenticated === true) {
        if (!store.getters[`${modulesName.userModuleName}/authenticated`]) {
          await store.dispatch(`${modulesName.userModuleName}/login`);
        }
      }

      if (meta.authorization.authenticated === false) {
        if (store.getters[`${modulesName.userModuleName}/authenticated`]) {
          return next({ name: 'home' });
        }
      }
    }
    // const canLeavePage = await store.dispatch('canLeavePage');
    // if (canLeavePage) {
    //   store.commit('setFormDirty', { value: false });
    //   next();
    // }
    const isWelcomePortal = to.matched.some(
      (item) => item.meta.isWelcomePortal
    );
    if (isWelcomePortal === true) {
      next();
    }

    return next();
  });
  router.afterEach(async (to) => {
    if (to.matched.some((route) => route.meta.IsParent)) {
      store.dispatch('setParentRouter', to);
    }
  });
};

// router.beforeEach(async (to, from, next) => {
//   const logged = sessionStorage.getItem('user-token'); //Temp this is not real fix

//  {
//     let result = to.hash != '' ? to.hash.split('&') : [];
//     store.commit('SetAccountData', result);

//     if (store.state.userHasValidRole === null) {
//       //Validate rol here
//       const hasValidRole = await validateApplicationRole().then((resp) => {
//         return resp.data;
//       });

//       if (!hasValidRole) {
//         store.commit('logoff');
//         return;
//       }

//       store.commit('setUserHasValidRole', hasValidRole);
//     }

//   }
// });
