import { http } from '@/core/api';
import interceptors from './interceptor';

const ax = http;

const HttpPlugin = (Vue) => {
  Vue.checkStudentTapRequest = (sieId) => {
    return http.get(`/api/tapRequests/gettaprequestbystudent/${sieId}`);
  };

  Vue.prototype.$ApiPost = (url, JsonParam, loading = true) => {
    return ax.post(url, JsonParam, {
      headers: {
        loading: loading,
      },
    });
  };

  Vue.prototype.$ApiGet = (url, JsonParam = {}, loading = false) => {
    return ax.get(
      url,
      {
        params: JsonParam,
      },
      {
        headers: {
          loading: loading,
        },
      }
    );
  };

  Vue.prototype.$GetBlob = async (url) => {
    const link = document.createElement('a');
    link.href = `${process.env.VUE_APP_URL_API}${url}`;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
  };

  Vue.prototype.$ApiDelete = (url, JsonParam, loading = false) => {
    return ax.delete(
      url,
      {
        params: JsonParam,
      },
      {
        headers: {
          loading: loading,
        },
      }
    );
  };

  Vue.prototype.$ApiPut = (url, data, loading = false) => {
    return ax.put(url, data, {
      headers: {
        loading: loading,
      },
    });
  };

  Vue.prototype.$Custom = () => {
    return http;
  };
  //Set overrides for Off Loading edged case.

  //Setup este interceptor esta deprecated
  ax.interceptors.response.use(
    (response) => interceptors.responseSuccessHandler(response),
    (error) => interceptors.responseErrorHandler(error)
  );
  ax.interceptors.request.use(
    (response) => interceptors.requestSuccessHandler(response),
    (error) => interceptors.requestErrorHandler(error)
  );
};

export default HttpPlugin;
