export default [
  {
    path: '/',
    meta: { authorization: { authenticated: true } },
    component: () => import('@/layout/Default/index.vue'),
    children: [
      {
        path: '',
        component: () => import('@/components/wrappers/StudentsWrapper.vue'),
        children: [
          {
            path: '',
            name: 'home',
            component: () => import('@/views/students/index.vue'),
          },
          {
            path: 'calendar',
            name: 'calendar',
            component: () => import('@/views/Calendar.vue'),
          },
          {
            path: '/operations/calendar/event-management/:id?',
            name: 'calendarEventManagement',
            component: () => import('@/views/CalendarEventManagement'),
          },
          {
            path: 'students/:studentSieId',
            props: true,
            component: () => import('@/components/wrappers/EmptyWrapper.vue'),
            children: [
              {
                path: '',
                props: true,
                component: () =>
                  import('@/components/wrappers/StudentBannerWrapper.vue'),
                children: [
                  {
                    path: 'information',
                    name: 'student-information',
                    props: true,
                    component: () => import('@/views/students/Information.vue'),
                  },
                  {
                    path: 'pei',
                    props: true,
                    component: () =>
                      import('@/components/wrappers/EmptyWrapper.vue'),
                    children: [
                      {
                        path: 'history',
                        name: 'student-pei-history',
                        props: true,
                        component: () =>
                          import('@/views/students/pei/History.vue'),
                      },
                      {
                        path: ':peiId',
                        props: true,
                        component: () =>
                          import('@/components/wrappers/EmptyWrapper.vue'),
                        children: [
                          {
                            path: 'controversy',
                            name: 'student-pei-controversy',
                            props: true,
                            component: () =>
                              import('@/views/students/pei/Controversy.vue'),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'evaluations',
                    name: 'student-evaluations_history',
                    props: true,
                    component: () =>
                      import('@/views/students/EvaluationsHistory.vue'),
                  },
                  {
                    path: 'service-history',
                    name: 'student-service_history',
                    props: true,
                    component: () =>
                      import('@/views/students/ServicesHistory.vue'),
                  },
                  {
                    path: 'provisional-remedy',
                    name: 'provisional-remedy',
                    props: true,
                    component: () =>
                      import('@/views/students/provisional-remedy/Search.vue'),
                  },
                  {
                    path: 'provisional-remedy-request/:requestId?/:stepIndex?',
                    name: 'provisional-remedy-request',
                    props: true,
                    component: () =>
                      import(
                        '@/views/students/provisional-remedy/ProvisionalRemedyRequest.vue'
                      ),
                  },
                  {
                    path: 'provisional-remedy/:requestId',
                    name: 'provisional-remedy-details',
                    props: true,
                    component: () =>
                      import(
                        '@/views/students/provisional-remedy/ProvisionalRemedyDetail.vue'
                      ),
                  },
                  {
                    path: 'contracts-history',
                    name: 'student-contracts_history',
                    props: true,
                    component: () =>
                      import('@/views/students/contracts-history/Main.vue'),
                  },
                  {
                    path: 'referral-evaluations',
                    name: 'student-referral_evaluations',
                    props: true,
                    component: () =>
                      import('@/views/students/referral-evaluations/Main.vue'),
                  },
                  {
                    path: 'complaints',
                    props: true,
                    component: () =>
                      import('@/components/wrappers/EmptyWrapper.vue'),
                    children: [
                      {
                        path: 'history',
                        name: 'student-complaints_history',
                        props: true,
                        component: () =>
                          import(
                            '@/views/students/complaints-history/Main.vue'
                          ),
                      },
                      {
                        path: 'filing',
                        name: 'student-complaints_filing',
                        props: true,
                        component: () =>
                          import(
                            '@/views/students/complaints-history/ComplaintFiling.vue'
                          ),
                      },
                      {
                        path: 'followUp/:complaintId',
                        name: 'student-complaints_followUp',
                        props: true,
                        component: () =>
                          import(
                            '@/views/students/complaints-history/ComplaintFollow.vue'
                          ),
                      },
                    ],
                  },
                  {
                    path: 'visits-history',
                    name: 'student-visits_history',
                    props: true,
                    component: () =>
                      import('@/views/students/visits-history/Main.vue'),
                  },
                  {
                    path: 'scholarship-attendance',
                    name: 'scholarship-attendance_history',
                    props: true,
                    component: () =>
                      import(
                        '@/views/students/scholarship-attendance/Main.vue'
                      ),
                  },
                  {
                    path: 'pay-refund',
                    name: 'pay-refund',
                    props: true,
                    component: () =>
                      import('@/views/students/pay-refund/Main.vue'),
                  },
                  {
                    path: '',
                    component: () =>
                      import('@/views/students/history-location/Wrapper.vue'),
                    children: [
                      {
                        path: 'history-location',
                        name: 'history-location',
                        component: () =>
                          import('@/views/students/history-location/Main.vue'),
                      },
                      {
                        path: 'reinbusement/:tapRequestId/:requestId/:ssn',
                        name: 'student-reinbursement',
                        component: () =>
                          import('@/views/students/reinbursement/Main.vue'),
                      },
                      {
                        path: 'academic-process-inform',
                        name: 'academic-process-inform',
                        props: true,
                        component: () =>
                          import('@/views/students/pei/AcademicProgressInform.vue'),
                      },
                    ],
                  },
                  {
                    path: 'academic-process-inform',
                    name: 'academic-process-inform',
                    props: true,
                    component: () =>
                      import('@/views/students/pei/AcademicProgressInform.vue'),
                  },
                  {
                    path: 'history-access-file',
                    name: 'history-access-file',
                    props: true,
                    component: () =>
                      import('@/views/students/history-access-file/Main.vue'),
                  },
                ],
              },
            ],
          },
          {
            path: 'message',
            name: 'message',
            component: () => import('@/views/message/MessageSearchWrapper.vue'),
          },
        ],
      },
      {
        path: 'profile',
        name: 'profile',
        component: () => import('@/views/Profile.vue'),
      },
    ],
  },
  {
    path: '*',
    redirect: '/',
  },
];
